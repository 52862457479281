.holdings {
	span {
		&.ticker {
			font-size: 14px;
			margin-left: 5px;
			margin-right: 5px;
		}
		&.title {
			font-size: 14px;
		}

		&.weight {
			font-size: 12px;
		}
	}
}