.header {
	// background: url('../../../public/logo.svg') no-repeat center;
	// background-size: 30%;

	nav {
		a {
			color: $body-color;
			text-decoration: none;
			padding: 0.5rem !important;

			&.active {
				border-bottom: 1px solid $gray-500;
			}
		}
	}
}

.navbar-brand {
	img {
		width: 215px;
	}
}

@media (max-width: 1024px) {
	.navbar-brand {
		img {
			width: 150px;
		}
	}
}
