$body-bg: #212529;
// $body-bg: #141718;
$body-color: #fff;

@import "~bootstrap/scss/bootstrap";

// @import "./custom.scss";
@import "./header.scss";

@import "./theme.css";

@import "./holdings.scss";